import { ApiInterface } from "./core/services/Api/types";
import { LangList } from "./lang";

export const SetPanebrasOpts = (api: ApiInterface) => {
    // Todas as alterações de tradução que forem exclusivas da Panebras, serão colocadas aqui.
    // Deverá existir o objeto e propriedade em todas as linguagens
    // Linguagem 0 = PT-BR; 1 = EN-uS


    LangList[0].modules.comercial.clientes.fields.InformacoesAdicionais = 'Horário de Funcionamento';
    LangList[1].modules.comercial.clientes.fields.InformacoesAdicionais = 'Opening Hours';
}